.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
}

.navbarContainer {
    position: fixed;
    bottom: 30px;
    z-index: 2;
}

.homeBackgroundWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeBackgroundName {
    font-size: 15vw;
    color: var(--maintext-color);
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}

.homeButton,
.darkModeButton {
    position: fixed;
    border-radius: 50%;
    border: none;
    background: none;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    cursor: pointer;
    color: var(--secondary-color);
    z-index: 2;
}

.homeButton {
    top: 10px;
    left: 10px;
}

.darkModeButton {
    top: 10px;
    right: 10px;
}

.section {
    width: 100%;
    z-index: 1;
}

@media screen and (max-width: 1024px) {
    .homeBackgroundName {
        margin-bottom: 70px;
    }
}
