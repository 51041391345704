.navbar {
    overflow: hidden;
    padding: 12px 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--primary-color);
    background: var(--secondary-color);
}

.navLinkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.navLink {
    text-decoration: none;
    font-size: small;
    letter-spacing: 1px;
}

.navLink.active {
    color: var(--active-color);
}

@media screen and (max-width: 1024px) {
    .navbar {
        padding: 10px 40px;
    }

    .navLinkContainer {
        gap: 40px;
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 10px 30px;
    }

    .navLinkContainer {
        gap: 30px;
    }
}
