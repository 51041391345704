.contact {
    padding: 80px;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--teriary-color) var(--contact-image) no-repeat center;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.contactTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}

.contactText {
    font-size: small;
    color: var(--secondary-color);
    text-align: center;
    font-weight: 300;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.mail {
    font-size: 60px;
    color: var(--secondary-color);
    font-weight: 600;
    cursor: pointer;
}

.copyEmailButton {
    background: var(--secondary-opacity-color);
    padding: 10px 20px;
    border-radius: 50px;
    border: none;
    color: var(--primary-color);
    font-size: x-small;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.copyEmailButton:hover {
    background: var(--secondary-color);
}

.cv {
    color: var(--secondary-color);
    text-decoration: none;
    text-decoration: underline;
    text-underline-offset: 7px;
    transition: all 0.2s ease-in-out;
}

.cv:hover {
    text-underline-offset: 12px;
}

.contactLinkContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#contactLinkContainer {
    margin-top: 50px;
}

.contactLinkBox {
    min-width: 280px;
    padding: 15px 20px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border: 1.5px dashed gray;
    transition: transform 0.2s ease-in-out;
}

#contactLinkBox1:hover {
    transform: translate(-5px, -5px) scale(1.05);
}

#contactLinkBox2:hover {
    transform: translate(5px, -5px) scale(1.05);
}

#contactLinkBox3:hover {
    transform: translate(-5px, 5px) scale(1.05);
}

#contactLinkBox4:hover {
    transform: translate(5px, 5px) scale(1.05);
}

.arrowIcon {
    color: gray;
    transition: transform 0.2s ease-in-out;
}

.contactLinkBox:hover .arrowIcon {
    transform: rotate(45deg);
}

.contactLinkText {
    font-size: large;
    color: var(--secondary-color);
}

@media screen and (max-width: 1024px) {
    .contact {
        padding: 50px;
    }

    .mail {
        font-size: 45px;
    }

    .copyEmailButton {
        padding: 10px 15px;
        background: var(--secondary-color);
    }

    .cv:hover {
        text-underline-offset: 7px;
    }

    .contactLinkBox {
        min-width: 250px;
    }

    .contactLinkText {
        font-size: medium;
    }

    #contactLinkBox1:hover,
    #contactLinkBox2:hover,
    #contactLinkBox3:hover,
    #contactLinkBox4:hover,
    .contactLinkBox:hover,
    .contactLinkBox:hover .arrowIcon {
        transform: none;
    }
}

@media screen and (max-width: 768px) {
    .contact {
        padding: 30px;
    }

    .mail {
        font-size: x-large;
    }

    .copyEmailButton {
        padding: 8px 12px;
        font-size: xx-small;
    }

    .contactLinkContainer {
        flex-direction: column;
        gap: 20px;
    }

    #contactLinkContainer {
        margin-top: 30px;
    }

    #contactText {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
}
