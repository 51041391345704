.about {
    padding: 80px;
    min-height: 100vh;
    width: 100vw;
    background: var(--teriary-color) var(--about-image) no-repeat center center / cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.heroLeftTextBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.heroNameContent {
    position: relative;
    cursor: pointer;
}

.heroTextTop {
    font-size: 70px;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 1px var(--secondary-color);
}

.heroText {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 70px;
    font-weight: 700;
    color: var(--secondary-color);
    animation: textAnimation 3s ease-in-out infinite;
}

@keyframes textAnimation {
    0%, 100% {
        clip-path: polygon(
            0% 40%, 
            10% 45%, 
            20% 35%, 
            30% 45%, 
            40% 55%, 
            50% 70%, 
            60% 75%, 
            70% 70%, 
            80% 60%, 
            90% 50%, 
            100% 60%, 
            100% 100%, 
            0% 100%
        );
    }
    50% {
        clip-path: polygon(
            0% 60%, 
            10% 50%, 
            20% 70%, 
            30% 60%, 
            40% 50%, 
            50% 35%, 
            60% 30%, 
            70% 35%, 
            80% 45%, 
            90% 55%, 
            100% 40%, 
            100% 100%, 
            0% 100%
        );
    }
}

.heroSubText {
    font-size: medium;
    color: var(--secondary-color);
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.animatedHeroSubText {
    font-weight: 500;
}

.heroSubText .cursor {
    display: inline-block;
    width: 3px;
    border-radius: 20px;
    height: 100%;
    background: currentColor;
    animation: blink 3s steps(1) infinite;
}
  
@keyframes blink {
    50% { opacity: 0; }
}

.heroDescription {
    width: 100%;
    font-size: small;
    font-weight: 300;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    color: var(--secondary-color);
}

.universityContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.universityLogo {
    width: 10%;
}

.universityName {
    font-size: xx-large;
    font-weight: 700;
    color: var(--secondary-color);
}

.universityDiscription {
    font-size: small;
    text-align: center;
    color: var(--secondary-color);
}

@media screen and (max-width: 1024px) {
    .about {
        padding: 50px;
    }

    .universityLogo {
        width: 18%;
    }
}

@media screen and (max-width: 768px) {
    .about {
        padding: 30px;
    }
    .heroTextTop, .heroText {
        font-size: 40px;
    }

    .heroDescription {
        font-size: x-small;
        line-height: 18px;
    }

    .heroSubText {
        font-size: small;
    }

    .animatedHeroSubText {
        font-size: medium;
    }

    .universityLogo {
        width: 35%;
    }
}
