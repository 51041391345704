.home {
    height: 100vh;
    width: 100vw;
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.homeTitle {
    font-size: 3rem;
    color: var(--secondary-color);
    text-align: center;
    z-index: 1;
    font-weight: 500;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .home {
        padding: 50px;
    }

    .homeTitle {
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 768px) {
    .home {
        padding: 30px;
    }

    .homeTitle {
        font-size: x-large;
    }
}
