.playground {
    padding: 70px;
    min-height: 100vh;
    width: 100vw;
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 80px;
}
  
.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border-radius: 20px;
    width: 100%;
    background: var(--primary-color);
}
  
.projectImageContainer {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.projectImageContainer::-webkit-scrollbar {
    display: none;
}

.projectImage {
    width: 45%;
    cursor: pointer;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
  
.projectTitle {
    width: 80%;
    font-size: xx-large;
    font-weight: 600;
    cursor: pointer;
    color: var(--secondary-color);
    text-align: center;
}
  
.projectDescription {
    text-align: center;
    line-height: 20px;
    font-size: small;
    font-weight: 300;
}
  
.projectButton {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background: var(--secondary-color);
    color: var(--primary-color);
    font-size: small;
    display: flex;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}
  
.projectButton:hover {
    transform: translateY(-5px);
}
  
@media screen and (max-width: 1024px) {
    .playground {
        padding: 40px;
    }
  
    .projectImageContainer {
        border-radius: 5px;
    }
  
    .projectImage {
        width: 90%;
    }
  
    .projectTitle {
        width: 100%;
        font-size: x-large;
    }
  
    .projectDescription {
        font-size: small;
        line-height: 18px;
    }
  
    .projectButton:hover {
        transform: none;
    }
}
  
@media screen and (max-width: 768px) {
    .playground {
        padding: 20px;
    }
  
    .projectImageContainer {
        gap: 10px;
    }
  
    .projectTitle {
        font-size: large;
    }
  
    .projectDescription {
        font-size: x-small;
        line-height: 18px;
    }
}
